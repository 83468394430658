export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#FFCB05" },
        { className: "secondary-bg-colour", colour: "#000" },
        { className: "tertiary-bg-colour", colour: "#000" },
        { className: "primary-free-bg-colour", colour: "#7C0096" }  ,
        { className: "secondary-free-bg-colour", colour: "#960033" },
        { className: "primary-vip-bg-colour", colour: "#FFCB05" },
        { className: "secondary-vip-bg-colour", colour: "#014867" },
        { className: "primary-vvip-bg-colour", colour: "#E60000" },
        { className: "secondary-vvip-bg-colour", colour: "#a80707" },
        { className: "misc-bg-colour", colour: "#000000" },
    ],
    text: [
        { className: "primary-text", colour: "#FFFFFF" },
        { className: "secondary-text", colour: "#000" },
        { className: "tertiary-text", colour: "#000F56" },
        { className: "primary-free-text-colour", colour: "#7C0096" },
        { className: "secondary-free-text-colour", colour: "#960033" },
        { className: "primary-vip-text-colour", colour: "#FFCB05" },
        { className: "secondary-vip-text-colour", colour: "#014867" },
        { className: "primary-vvip-text-colour", colour: "#E60000" },
        { className: "secondary-vvip-text-colour", colour: "#2A6000" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#EF151C" },
        { className: "secondary-border-colour", colour: "#FFCB05" },
        { className: "primary-free-border-colour", colour: "#7C0096" },
        { className: "secondary-free-border-colour", colour: "#960033" },
        { className: "primary-vip-border-colour", colour: "#FFCB05" },
        { className: "secondary-vip-border-colour", colour: "#014867" },
        { className: "primary-vvip-border-colour", colour: "#E60000" },
        { className: "secondary-vvip-border-colour", colour: "#a80707" },
    ],
};
