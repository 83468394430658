<template>
  <div class="d-flex flex-column justify-content-center">
    <div v-if="userStats" class="d-flex flex-row coins-container primary-text"
         :class="getColoursByServiceId(user.serviceId, 'bg')">
      <img class="coins-icon" :src="require('../../assets/icons/coin.png')">
      <div class="d-flex flex-column col-9">
        <div class="coins-text"> MY COINS</div>
        <div class="coins-text"> {{ userStats.TotalCoins }}</div>
      </div>
    </div>
    <img v-if="this.getServiceIdMapNumber(user.serviceId) === 1" class="vip-logo"
         :src="require(`../../brand/${brandConfig.brand}/assets/icons/free-icon.webp`)">
    <img v-if="this.getServiceIdMapNumber(user.serviceId) === 2" class="vip-logo"
         :src="require(`../../brand/${brandConfig.brand}/assets/icons/vip-icon.webp`)">
    <img v-if="this.getServiceIdMapNumber(user.serviceId) === 3" class="vip-logo"
         :src="require(`../../brand/${brandConfig.brand}/assets/icons/vvip-icon.webp`)">
  </div>
</template>

<script>
import {serviceIdMap} from "@/config";
import {mapState} from "vuex";

export default {
  name: "PlayerServiceBoxOne",
  computed: {
    ...mapState(['user', 'brandConfig', 'userStats'])
  },
  methods: {
    getServiceIdMapNumber(service) {
      return serviceIdMap.get(service)
    },
    getColoursByServiceId(serviceId, type) {
      if (this.getServiceIdMapNumber(serviceId) === 1) return `primary-free-${type}-colour`
      if (this.getServiceIdMapNumber(serviceId) === 2) return `primary-vip-${type}-colour`
      if (this.getServiceIdMapNumber(serviceId) === 3) return `primary-vvip-${type}-colour`
    },
  }
}
</script>

<style scoped>
.coins-icon {
  margin-left: 5px;
  width: 35px;
  font-size: 14px;
}

.coins-text {
  margin-right: 8px;
  font-size: 14px;
}

.coins-container {
  top: 10px;
  right: 8px;
  border-radius: 8px;
  opacity: 0.8;
  border: 3px solid #FFFFFF;
  width: 125px;
}

.vip-logo {
  position: absolute;
  top: 24px;
  right: -25px;
  width: 100px;
  height: auto;
}
</style>
