import axios from "axios";
import {environmentConfig} from "@/config/enviromentConfig";


export default class DepService {
    static async trackLogin(msisdn, productId, service) {
        try {
            return await axios.post(`${environmentConfig.depService}/track-user-login`, {
                    msisdn,
                    productId,
                    serviceId: service,
                    isWeb: 1,
                },
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackLoginV2(encryptedMsisdn, serviceId) {
        try {
            return await axios.post(`${environmentConfig.depService}/track-login/v2`, {
                    encryptedMsisdn,
                    service: serviceId,
                    isWeb: 1,
                },
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async doiOne(msisdn, service, productId) {
        return await axios.post(`${environmentConfig.depService}/upsell/doi-one`, {
                msisdn,
                serviceId: service,
                productId,
                externalRef: 1234,
                channel: 'WAP',
                doiChannel: "WAP_REDIRECT"
            },
        )
    }

    static async doiTwo(msisdn, service) {
        return await axios.post(`${environmentConfig.depService}/upsell/doi-two`, {
                msisdn,
                serviceId: service,
                origination: "Fanclash - Web",
            },
        )
    }

    static async requestOtp(msisdn, productId) {
        return await axios.post(`${environmentConfig.depService}/request-otp`, {
                msisdn: msisdn,
                productId: productId,
            },
        )
    }

    static async validateOtp(msisdn, productId, otp) {
        return await axios.post(`${environmentConfig.depService}/validate-otp`, {
                msisdn: msisdn,
                productId,
                otp: otp,
            },
        )
    }

    static async subscribeUser(msisdn, serviceId, subscriptionId, productId, campaignId = 0, clickId) {
        return await axios.post(`${environmentConfig.depService}/upsell/subscribe`, {
                msisdn,
                serviceId,
                subscriptionId,
                productId,
                campaignId,
                clickId
            },
        )
    }

    static async subscribeEncryptedUser(encryptedMsisdn, token, campaignId, evinaToken, contentOverride = undefined, googleClickId, serviceId = 'vc-fanclash-beatthejams-01') {
        try {
            return await axios.post(`${environmentConfig.depService}/subscribe/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: serviceId,
                    "externalRef": 12335642,
                    revenuePartnerId: 33,
                    campaignId: campaignId,
                    acquisitionChannel: 'Organic-Web',
                    evinaToken: evinaToken,
                    contentOverride: contentOverride,
                    googleClickId
                },
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async trackUserUpgrade(msisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.depService}/track-multi-tier-upgrade`, {
                msisdn,
                productId,
                oldService,
                newService,
            },
        )
    }

    static async trackUserUpgradeV2(encryptedMsisdn, productId, oldService, newService) {
        return await axios.post(`${environmentConfig.depService}/track-multi-tier-upgrade/v2`, {
                encryptedMsisdn: encryptedMsisdn,
                productId,
                oldService,
                newService,
            },
        )
    }

    static async unSubscribeUser(subscriptionId) {
        return await axios.post(`${environmentConfig.depService}/unsubscribe`, {
                subscriptionId
            }, {
                headers: {
                    Authorization: `Basic ${environmentConfig.authHeaderUnsubscribe}`
                }
            }
        )
    }

    static async unsubscribeEncryptedUser(encryptedMsisdn, serviceId, isDcbOnly) {
        try {
            return await axios.post(`${environmentConfig.depService}/unsubscribe/v2`, {
                    encryptedMsisdn,
                    serviceId: serviceId,
                    "externalRef": 12335642,
                    isDcbOnly: isDcbOnly
                },
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaProductId(msisdn, productId) {
        try {
            return await axios.post(`${environmentConfig.depService}/get-user-via-msisdn`, {
                    msisdn,
                    productId: productId,
                },
            )
        } catch (e) {
            return {authError: true};
        }
    }

    static async getUserViaEncryptedMsisdn(encryptedMsisdn, token, serviceId) {
        try {
            return await axios.post(`${environmentConfig.depService}/get-user-via-encrypted-msisdn/v2`, {
                    encryptedMsisdn: encryptedMsisdn,
                    token: token,
                    serviceId: serviceId,
                    externalRef: 1233564,
                },
            )
        } catch (e) {
            return {authError: true};
        }
    }


    static async createUsername(msisdn, productId, username) {
        return await axios.post(`${environmentConfig.depService}/update-username`, {
                msisdn,
                productId,
                username
            },
        )
    }

    static async updateAvatarFromMsisdn(msisdn, productId, avatar) {
        return await axios.post(`${environmentConfig.depService}/update-avatar-from-msisdn`, {
                msisdn,
                productId,
                avatar
            },
        )
    }

    static async decryptMsisdn(encryptedMsisdn) {
        return await axios.post(`${environmentConfig.depService}/decrypt-msisdn`, {
                encryptedMsisdn,
            },
        )
    }

    static async addFunds(msisdn, productId, gameFundsTypeId, gameFundsAllocationTypeId, value, fromGameId) {
        return await axios.post(`${environmentConfig.depService}/add-game-funds`, {
            msisdn,
            productId,
            gameFundsTypeId,
            gameFundsAllocationTypeId,
            value,
            fromGameId
        })
    }

    // static async createSessionId(msisdn, serviceId) {
    //     return await axios.post(`${environmentConfig.depService}/create-session-id`, {
    //             msisdn: msisdn,
    //             serviceId: serviceId,
    //         },
    //     )
    // }

}
