export const serviceIdMap = new Map();
export const mtnServiceIdMap = new Map

export const ServiceIds = {}

const serviceIdsVodacom = {
    free: "vc-fanclash-musictrivia-00",
    vip: "vc-fanclash-beatthejams-01",
    vvip: "vc-fanclash-beatthejams-02"
}

const ServiceIdsMtn = {
    free: "mzansi-rhythm-free",
    vipDaily: "mzansi-rhythm-daily",
    vipWeekly: "mzansi-rhythm-weekly",
    vipMonthly: "mzansi-rhythm-monthly"
}


serviceIdMap.set(ServiceIds.free, 1);
serviceIdMap.set(ServiceIds.vip, 2);
serviceIdMap.set(ServiceIds.vvip, 3);

mtnServiceIdMap.set(ServiceIdsMtn.free, 1);
mtnServiceIdMap.set(ServiceIdsMtn.vipDaily, 2);
mtnServiceIdMap.set(ServiceIdsMtn.vipWeekly, 3);
mtnServiceIdMap.set(ServiceIdsMtn.vipMonthly, 4);


export function setServiceIds(brand) {
    if (brand === 'vc-za-en' || brand === 'vc-encrypt-za-en') {
        serviceIdMap.set(serviceIdsVodacom.free, 1);
        serviceIdMap.set(serviceIdsVodacom.vip, 2);
        serviceIdMap.set(serviceIdsVodacom.vvip, 3);
    } else {
        serviceIdMap.set(ServiceIdsMtn.free, 1);
        serviceIdMap.set(ServiceIdsMtn.vipDaily, 2);
        serviceIdMap.set(ServiceIdsMtn.vipWeekly, 2);
        serviceIdMap.set(ServiceIdsMtn.vipMonthly, 2);
    }
}

export function isVodacom(brand) {
    return brand === 'vc-za-en' || brand === 'vc-encrypt-za-en'
}
