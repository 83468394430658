import {createRouter, createWebHistory} from 'vue-router'
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";
import {utagDataPost} from "@/functions/utagDataPost";

const routes = [
    {
        path: '/',
        redirect: getBaseRoute(),
    },
    {
        path: '/welcome',
        name: 'WelcomePage',
        component: () => import("@/views/pages/onboarding/WelcomePage"),
    },
    {
        path: '/landing',
        name: 'LandingPage',
        component: () => import("@/views/pages/LandingPage"),
    },
    {
        path: '/login',
        name: 'RequestOTP',
        component: () => import("@/views/pages/onboarding/RequestOTP"),
    },
    {
        path: '/submit-otp',
        name: 'SubmitOTP',
        component: () => import("@/views/pages/onboarding/SubmitOTP"),
    },
    {
        path: '/opt-in',
        name: 'DoiOne',
        component: () => import("@/views/pages/onboarding/DoiOne"),
    },
    {
        path: '/opt-in-confirmation',
        name: 'DoiTwo',
        component: () => import("@/views/pages/onboarding/DoiTwo"),
    },
    {
        path: '/create-username',
        name: 'CreateUsername',
        component: () => import("@/views/pages/onboarding/CreateUsername"),
    },
    {
        path: '/profile',
        name: 'ProfilePage',
        component: () => import("@/views/pages/ProfilePage"),
    },
    {
        path: '/choose-avatar',
        name: 'ChooseAvatar',
        component: () => import("@/views/pages/ChooseAvatar"),
    },
    {
        path: '/prizes',
        name: 'PrizesList',
        component: () => import("@/views/pages/PrizesList"),
    },
    {
        path: '/manage-subscription',
        name: 'ManageSubscription',
        component: () => import("@/views/pages/ManageSubscription"),
    },
    {
        path: '/error/:errorHeader',
        name: 'ErrorScreen',
        props: true,
        component: () => import("@/views/pages/errors/ErrorScreen"),
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsConditions',
        component: () => import("@/views/pages/TermsConditions"),
    },
    {
        path: '/how-to-play',
        name: 'HowToPlay',
        component: () => import("@/views/pages/HowToPlay"),
    },
    {
        path: '/quiz-completed',
        name: 'QuizCompletedPage',
        component: () => import("@/views/pages/QuizCompletedPage"),
    },
    {
        path: '/questions',
        name: 'QuestionView',
        component: () => import("@/views/pages/QuestionPage"),
    },
    {
        path: '/lucky-list',
        name: 'LuckyListPage',
        component: () => import("@/views/pages/LuckyListPage"),
    },
    {
        path: '/leaderboard',
        name: 'LeaderboardPage',
        component: () => import("@/views/pages/LeaderboardPage"),
    },
    {
        path: '/upsell',
        name: 'UpsellPage',
        component: () => import("@/views/pages/UpsellPage"),
    },
    {
        path: '/bonus-round-opt-in',
        name: 'BonusRoundPage',
        component: () => import("@/views/components/BonusRoundPage"),
    },
    {
        path: '/timeout-error',
        name: 'TimeoutError',
        component: () => import("@/views/pages/errors/TimeoutError"),
    },
    {
        path: '/wifi',
        name: 'Wifi',
        component: () => import("@/views/pages/errors/OnWifi"),
    },
    {
        path: '/the-fuse',
        name: 'TheFuseRssFeed',
        component: () => import("@/views/pages/TheFuseRssFeed"),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        window.scrollTo(0, 0);
    }
})

function getBaseRoute() {
    const domain = window.origin;
    const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
    return brandConfig.baseRoute;
}

router.beforeEach(async (to, from, next) => {
    await userAuthenticate(to, from, next);
})

router.afterEach((to, from, next) => utagDataPost(to, from, next));

export default router
