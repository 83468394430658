export const brandConfigObjectMap = new Map();

export const Brands = {
    VodacomSouthAfricaEnglish: 'vc-za-en',
    MtnEnglish: 'mtn-za-en',
    VodacomEvinaSouthAfricaEnglish: 'vc-encrypt-za-en',
};

brandConfigObjectMap.set(Brands.VodacomSouthAfricaEnglish, {
    gameTitle: 'Beat the Jams',
    freePlayServiceId: 'vc-fanclash-musictrivia-00',
    baseRoute: '/welcome'
});

brandConfigObjectMap.set(Brands.VodacomEvinaSouthAfricaEnglish, {
    gameTitle: 'Beat the Jams',
    freePlayServiceId: 'vc-fanclash-musictrivia-00',
    newIntegration: true,
    baseRoute: '/opt-in-confirmation'
});

brandConfigObjectMap.set(Brands.MtnEnglish, {
    gameTitle: 'Mzansi Rhythm',
    freePlayServiceId: 'vc-fanclash-musictrivia-00',
    baseRoute: '/welcome'
});
