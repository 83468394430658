<template>
  <footer class="d-flex flex-column justify-content-center p-1 footer-container"
          :class="getColoursByServiceId(user.serviceId, 'bg')">
    <div class="d-flex justify-content-evenly h-100">
      <div @click="routeToPage('landing')" class="d-flex flex-column text-center footer-navbar-img col-2"
           :class="setActivePageIconBGColour('landing')">
        <div><img
            :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav_home.webp`)"></div>
        <div class="footer-text mt-1">Home</div>
      </div>
      <div @click="routeToPage('how-to-play')" class="d-flex flex-column text-center footer-navbar-img col-2"
           :class="setActivePageIconBGColour('how-to-play')">
        <div><img
            :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav_how_to_play.webp`)"></div>
        <div class="footer-text mt-1">How to play</div>
      </div>
      <div @click="routeToPage('prizes')" class="d-flex flex-column text-center footer-navbar-img col-2"
           :class="setActivePageIconBGColour('prizes')">
        <div><img
            :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav_prizes.webp`)"></div>
        <div class="footer-text mt-1">Prizes</div>
      </div>
      <div v-if="!showLeaderboard()"
           class="d-flex flex-column text-center footer-navbar-img col-2"
           :class="setActivePageIconBGColour('leaderboard')">
        <div v-if="this.getServiceIdMapNumber(user.serviceId) !== 3 && showLeaderBoardPopup" class="position-relative">
          <img @click="onLeaderBoardPopUpClicked()"
               :src="require(`@/brand/${brandConfig.brand}/assets/navFooter/btn_close.webp`)" class="close-btn">
          <img @click="onLeaderBoardPopUpClicked()" class="upsell-leaderboard-popup"
               :src="require(`@/brand/${brandConfig.brand}/assets/popups/upsell-leaderboard-footer-popup.webp`)">
        </div>
        <div @click="onLeaderboardClosedClicked()">
          <img :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav_leaderboard_lock.png`)">
        </div>
        <div class="footer-text locked mt-1">{{ formatLeaderboardText(brandConfig.brand) }}</div>
      </div>
      <div v-if="showLeaderboard()" @click="routeToBrandLeaderboard()"
           class="d-flex flex-column text-center footer-navbar-img col-2"
           :class="setActivePageIconBGColour('leaderboard')">
        <div><img
            :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav_leaderboard.png`)"></div>
        <div class="footer-text mt-1">{{ formatLeaderboardText(brandConfig.brand) }}</div>
      </div>
      <div @click="routeToPage('profile')" class="d-flex flex-column text-center footer-navbar-img col-2"
           :class="setActivePageIconBGColour('profile')">
        <div><img
            :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav_profile.webp`)"></div>
        <div class="footer-text mt-1">Profile</div>
      </div>
    </div>
  </footer>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import {serviceIdMap} from "@/config";
import {isVodacom} from "@/config";

export default {
  name: "Footer",
  computed: {
    ...mapState(['brandConfig', 'user', 'showLeaderBoardPopup']),
  },
  methods: {
    ...mapMutations(['setShowLeaderBoardPopup']),
    onLeaderboardClosedClicked() {
      this.setShowLeaderBoardPopup(true)
    },
    isBrandVodacom(brand) {
      return isVodacom(brand)
    },
    showLeaderboard() {
      if (this.isBrandVodacom(this.brandConfig.brand)) {
        return this.user.serviceId === 'vc-fanclash-beatthejams-02'
      } else return this.user.serviceId !== "mzansi-rhythm-free"
    },
    formatLeaderboardText() {
      if (this.isBrandVodacom(this.brandConfig.brand)) {
        return 'Lucky List'
      } else return 'Leaderboard';
    },
    onLeaderBoardPopUpClicked() {
      this.setShowLeaderBoardPopup(false);
    },
    routeToBrandLeaderboard() {
      if (this.isBrandVodacom(this.brandConfig.brand)) {
        return this.$router.push(`/lucky-list`);
      } else return this.$router.push(`/leaderboard`);
    },
    getServiceIdMapNumber(service) {
      return serviceIdMap.get(service)
    },
    getColoursByServiceId(serviceId, type) {
      if (isVodacom(this.brandConfig.brand)) {
        if (this.getServiceIdMapNumber(serviceId) === 1) return `primary-free-${type}-colour`
        if (this.getServiceIdMapNumber(serviceId) === 2) return `primary-vip-${type}-colour`
        if (this.getServiceIdMapNumber(serviceId) === 3) return `primary-vvip-${type}-colour`
      } else return 'tertiary-bg-colour'

    },
    getSecondaryColoursByServiceId(serviceId, type) {
      if (isVodacom(this.brandConfig.brand)) {
        if (this.getServiceIdMapNumber(serviceId) === 1) return `secondary-free-${type}-colour`
        if (this.getServiceIdMapNumber(serviceId) === 2) return `secondary-vip-${type}-colour`
        if (this.getServiceIdMapNumber(serviceId) === 3) return `secondary-vvip-${type}-colour`
      } else {
        if (this.getServiceIdMapNumber(serviceId) === 1) return `primary-free-${type}-colour`
        if (this.getServiceIdMapNumber(serviceId) > 1) return `primary-vip-${type}-colour`
      }
    },
    setActivePageIconBGColour(icon) {
      if (icon === "landing" && this.$route.name === "LandingPage") return this.getSecondaryColoursByServiceId(this.user.serviceId, 'bg');
      if (icon === "how-to-play" && this.$route.name === "HowToPlay") return this.getSecondaryColoursByServiceId(this.user.serviceId, 'bg');
      if (icon === "prizes" && this.$route.name === "PrizesList") return this.getSecondaryColoursByServiceId(this.user.serviceId, 'bg');
      if (icon === "leaderboard" && this.$route.name === "LeaderboardPage") return this.getSecondaryColoursByServiceId(this.user.serviceId, 'bg');
      if (icon === "leaderboard" && this.$route.name === "LuckyListPage") return this.getSecondaryColoursByServiceId(this.user.serviceId, 'bg');
      if (icon === "profile" && this.$route.name === "ProfilePage") return this.getSecondaryColoursByServiceId(this.user.serviceId, 'bg');
    },
    routeToPage(pageRoute) {
      this.$router.push(`/${pageRoute}`);
    },
  },
}
</script>

<style scoped>
.footer-container {
  position: fixed;
  z-index: 2;
  bottom: 0;
  border-radius: 10px 10px 0 0;
  width: inherit;
  height: 60px;
}

.my-rounds-nav-icon-img-container img {
  width: 30px;
  height: 30px;
}

.footer-navbar-img {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  position: relative;
}

.footer-navbar-img img, .footer-navbar-rounds-img img {
  width: 25px;
  height: auto;
}

.footer-text {
  font-size: xx-small;
  color: white;
}

.close-btn {
  position: absolute;
  z-index: 4;
  bottom: 30px;
  right: -25px;
}

.upsell-leaderboard-popup {
  position: absolute;
  width: 125px !important;
  z-index: 3 !important;
  bottom: 0;
  right: -15px;
}

.locked {
  opacity: 0.3;
}

</style>
