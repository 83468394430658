<template>
  <div class="d-flex flex-column align-items-center">
    <div
        :style="{ 'background-image' : 'url(' + require(`@/brand/${brandConfig.brand}/assets/backgrounds/plain-bg.webp`) + ')'}"
        class="app-container col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3 vue-container">
      <LoadingOverlay class="col-12 col-sm-8 col-md-6 col-lg-5 col-xl-3" v-if="this.isLoading"/>
      <router-view/>
    </div>
  </div>
</template>

<script>
import LoadingOverlay from "@/views/components/LoadingOverlay";
import {mapState, mapMutations} from "vuex";
import {setBrandConfig} from "@/functions/setBrandConfig";
import AddAppToHome from "@/views/components/AddAppToHome";
import {isVodacom} from "@/config";

export default {
  components: {LoadingOverlay, AddAppToHome},
  computed: {
    ...mapState(['isLoading', 'brandConfig', 'user'])
  },
  methods: {
    ...mapMutations(['setShowAddToHomeScreen', 'setDeferredPrompt', 'setProductId']),
    injectTealium() {
      (function (a, b, c, d) {
        a = 'https://tags.tiqcdn.com/utag/vodafone/za-beatthejams/prod/utag.js';
        b = document;
        c = 'script';
        d = b.createElement(c);
        d.src = a;
        d.type = 'text/java' + c;
        d.async = true;
        a = b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d, a);
      })();
    },
    setFavicon() {
      let iconPath = this.brandConfig.brand === "mtn-za-en" ? 'mtn' : 'vodacom';
      let manifest = {
        name: "App name",
        icons: [{
          src: `./img/${iconPath}/icons/android-chrome-192x192.png`,
          sizes: "192x192",
          type: "image/png"
        }, {
          src: `./img/${iconPath}/icons/android-chrome-512x512.png`,
          sizes: "512x512",
          type: "image/png"
        }, {
          src: `./img/${iconPath}/icons/android-chrome-maskable-192x192.png`,
          sizes: "192x192",
          type: "image/png"
        }, {
          src: `./img/${iconPath}/icons/android-chrome-maskable-512x512.png`,
          sizes: "512x512",
          type: "image/png"
        }]
      };
      let content = encodeURIComponent(JSON.stringify(manifest));
      let url = "data:application/manifest+json," + content;
      let element = document.createElement('link');
      element.setAttribute('rel', 'manifest');
      element.setAttribute('href', url);
      document.querySelector('head').appendChild(element);
    },
    setTabName() {
      document.title = this.brandConfig.gameTitle;
    },
    async showHomeScreenPopup() {
      // const showPopup = await TriviaService.showPopup(this.user.msisdn, this.user.serviceId, "AddToHomeScreen");
      // if (showPopup.data) this.setShowAddToHomeScreen(true);
      // else this.setShowAddToHomeScreen(false);
    },
    captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault()
        // Stash the event so it can be triggered later.
        this.setDeferredPrompt(e)
      })
    },
    setProductIdOffBrand() {
      if (isVodacom(this.brandConfig.brand)) this.setProductId(18)
      if (!isVodacom(this.brandConfig.brand)) this.setProductId(35)
    },
  },
  async beforeMount() {
    setBrandConfig();
    this.setProductIdOffBrand();
    if (this.brandConfig.brand === 'vc-za-en' || this.brandConfig.brand === 'vc-encrypt-za-en') this.injectTealium();
    this.setFavicon()
    this.setTabName();
    await this.showHomeScreenPopup();
    this.captureEvent();
  },
}
</script>

<style>
.vue-container {
  position: relative;
  font-family: 'Poppins', sans-serif;
  background-size: cover;
  min-height: 100vh;
  z-index: 0;
}
</style>
