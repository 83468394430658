import store from "@/store";
import DcbService from "../services/dcbService";
import {ServiceIds} from "../config";
import manageSubscription from "../views/pages/ManageSubscription";

export async function handleActionClick(user, action, next) {
    const urlParams = new URLSearchParams(window.location.search);
    const evinaToken = urlParams.get('evinaToken') ?? localStorage.getItem('evinaToken') ?? store.state.evinaToken;
    if (action === 'accept') await handleAcceptButton(evinaToken, user, next);
    else if (action === 'accept-tier2-v1') await handleAcceptButtonTier2Version1(user, next);
    else await handleExitButton(evinaToken, user);
}


async function handleExitButton(evinaToken, user) {
    store.commit('setIsLoading', true);
    let contentEnabled;
    if (user?.contentBlockedEnabled) contentEnabled = "CBADP"
    else contentEnabled = "UDP"
    await DcbService.subscribeEncryptedUser(store.state.encryptedMsisdn, store.state.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
    store.commit('setIsLoading', false);
    window.location = 'https://www.vodacom.co.za/vodacom/shopping/v/add-to-bill';

}

async function handleAcceptButton(evinaToken, user, next) {
    store.commit('setIsLoading', true);
    let contentEnabled;
    if (user?.contentBlockedEnabled) contentEnabled = "CBAAP";
    else contentEnabled = "UAP"
    await DcbService.trackUserUpgradeV2(user.token, 18, ServiceIds.free, ServiceIds.vip);
    const subUserResponse = await DcbService.subscribeEncryptedUser(user.encryptedMsisdn, user.token, store.state.campaignId, evinaToken, contentEnabled, store.state.googleClickId);
    await handleRouting(subUserResponse.data, next);
}

async function handleRouting(userSubData, next) {
    if (userSubData.result === true) {
        setUserSessionData(userSubData.token, userSubData.serviceId);
        const user = await DcbService.getUserViaEncryptedMsisdn(userSubData.token, userSubData.serviceId, userSubData.serviceId);
        store.commit('setUser', user.data);
        store.commit('changeUserServiceId', userSubData.serviceId);
        return next({name: 'LandingPage'});
    } else handleSubErrorResponse(userSubData, next);
}

function handleSubErrorResponse(userSubData, next) {
    if (userSubData.status) return next({path: `/error/:${userSubData.status}`});
}

async function handleAcceptButtonTier2Version1(user, next) {
    store.commit('setIsLoading', true);
    await DcbService.trackUserUpgrade(user.msisdn, 18, ServiceIds.free, ServiceIds.vip);
    await DcbService.doiTwo(user.msisdn, ServiceIds.vip);
    const subscribeUserResponse = await DcbService.subscribeUser(user.msisdn, ServiceIds.vip, store.state.campaignId, store.state.clickId);
    await handleUserSubscribeResponse(user, subscribeUserResponse.data, next);
}

async function handleUserSubscribeResponse(user, subscribeUserResponse, next) {
    if (subscribeUserResponse.result) {
        setUserSessionData(ServiceIds.vip);
        const getUserResponse =  await DcbService.getUserViaProductId(user.msisdn, store.state.productId);
        store.commit('setUser', getUserResponse.data);
        store.commit('changeUserServiceId', ServiceIds.vip);
        store.commit('setIsLoading', false);
        return next({name: 'LandingPage'});
    } else return next({path: `/error/:General error`});
}

function setUserSessionData(token, serviceId) {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('serviceId', serviceId);
    store.commit('setServiceId', serviceId);
}
