import store from "@/store";
import isMobile from "@/functions/deviceDetection";

function addDaysToDateTime(date, n){
    const d = new Date(date);
    d.setDate(d.getDate() + n);
    return d.toLocaleDateString();
}

export function utagDataPost(to, from, next) {
    if (store.state.brandConfig.brand === 'vc-za-en' || store.state.brandConfig.brand === 'vc-encrypt-za-en') {
        const utagData = {
            tealium_event: "view",
            page_name: `beatthejams:${to.path.substring(1, to.path.length)}`,
            page_section: `beatthejams:${to.path.substring(1, to.path.length)}`,
            page_parent_domain: window.origin,
            page_country: "south africa",
            page_url: `${window.origin}${to.fullPath}`,
            page_locale: "za",
            page_title: document.title,
            page_platform: isMobile() ? 'web-mobile' : 'web-desktop',
            visitor_id_asset_active: `${store.state.user?.subscriptionId}`,
            visitor_login_status: store.state.user && (to.path !== '/login' || to.path !== '/submit-otp') ? `loggedin` : `loggedout`,
            site_version: "1.0",
            site_type: "web"
        };

        if (from.path === '/opt-in-confirmation' && to.path === '/landing') {
            Object.assign(utagData, {
                event_name: "subscription",
                product_id: [`${store.state.user.serviceId}`],
                product_name: ["Beat the Jams"],
                product_price: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? ["3.00"] : ["7.00"],
                product_category: ["Subscription"],
                product_sku: ["12345"],
                product_type: "Mobile",
                visitor_asset_plan_active: ["Beat the Jams"],
                visitor_asset_plan_id_active: `${store.state.user?.subscriptionId}`,
                visitor_asset_subscription_id_active: `${store.state.user?.subscriptionId}`,
                visitor_asset_plan_name_active: ["Beat the Jams"],
                visitor_asset_plan_type_active: "Subscription",
                visitor_asset_plan_brand_active: "games",
                visitor_asset_duration_active: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? "1 Days" : "7 Days",
                visitor_assest_type_active: "free trial",
                visitor_assest_date_end_active: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? addDaysToDateTime(new Date().toLocaleDateString(), 1) : addDaysToDateTime(new Date().toLocaleDateString(), 7),
                visitor_assest_date_start_active: new Date().toLocaleDateString(),
                visitor_addon_name_active: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? "1 Days" : "7 Days",
                visitor_addon_type_active: "free trial",
                visitor_addon_date_end_active: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? addDaysToDateTime(new Date().toLocaleDateString(), 1) : addDaysToDateTime(new Date().toLocaleDateString(), 7),
                visitor_addon_date_start_active: new Date().toLocaleDateString(),
                subscription_completed: 1,
                transaction_id: `${store.state.user?.subscriptionId}`,
                order_revenue: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? "3.00" : "7.00",
                order_completed: 1,
                subscription_type: store.state.user.serviceId === `vc-fanclash-beatthejams-01` ? 'daily' : 'weekly'
            })
        }

        if (to.path === '/landing' && (from.path === '/login' || from.path === '/submit-otp')) Object.assign(utagData, { login_successful: 1 })

        if (utag) utag.view(utagData);
    }
}
