export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#E7562A" },
        { className: "secondary-bg-colour", colour: "#DE1F5F" },
        { className: "tertiary-bg-colour", colour: "#e34d7c" },
        { className: "primary-free-bg-colour", colour: "#C10041" },
        { className: "secondary-free-bg-colour", colour: "#960033" },
        { className: "primary-vip-bg-colour", colour: "#007FB7" },
        { className: "secondary-vip-bg-colour", colour: "#014867" },
        { className: "primary-vvip-bg-colour", colour: "#E60000" },
        { className: "secondary-vvip-bg-colour", colour: "#a80707" },
        { className: "misc-bg-colour", colour: "#000000" },
    ],
    text: [
        { className: "primary-text", colour: "#FFFFFF" },
        { className: "secondary-text", colour: "#DE1F5F" },
        { className: "tertiary-text", colour: "#E7562A" },
        { className: "primary-free-text-colour", colour: "#C10041" },
        { className: "secondary-free-text-colour", colour: "#960033" },
        { className: "primary-vip-text-colour", colour: "#007FB7" },
        { className: "secondary-vip-text-colour", colour: "#014867" },
        { className: "primary-vvip-text-colour", colour: "#E60000" },
        { className: "secondary-vvip-text-colour", colour: "#2A6000" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#EF151C" },
        { className: "secondary-border-colour", colour: "#EF911B" },
        { className: "primary-free-border-colour", colour: "#C10041" },
        { className: "secondary-free-border-colour", colour: "#960033" },
        { className: "primary-vip-border-colour", colour: "#007FB7" },
        { className: "secondary-vip-border-colour", colour: "#014867" },
        { className: "primary-vvip-border-colour", colour: "#E60000" },
        { className: "secondary-vvip-border-colour", colour: "#a80707" },
    ],
};
