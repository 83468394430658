<template>
  <div class="d-flex flex-column loading-overlay">
    <div class="d-flex flex-column align-items-center spinner">
      <img class="col-3" :src="require(`@/brand/${brandConfig.brand}/assets/gifs/loading.gif`)">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: 'LoadingOverlay',
  computed: {
    ...mapState(['user', 'brandConfig'])
  },
  data() {
    return {}
  },
}
</script>

<style scoped>
.loading-overlay {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: rgba(59, 58, 58, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  overflow: hidden;
}

.spinner {
  display: inline-block;
}
</style>
